/* eslint no-useless-return: 0 */

import { IonContent, IonDatetime, IonModal, IonDatetimeButton, IonFooter } from '@ionic/react';
import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import SearchPageComponent from 'components/core/SearchPageComponents';
import useEventCatgories from 'features/event/hooks/useEventCategories';
import { getEvents } from 'lib/ApiService';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function EventSearchPage() {
    const { t } = useTranslation();

    const [filterDate, setFilterDate] = useState<string>('');
    const [filterCategory, setFilterCategory] = useState<[]>([]);

    const { categories } = useEventCatgories();

    const [events, setEvents] = useState<any[]>([]);

    const handleTypeChange = (opts: any) => {
        setFilterCategory(opts);
    };

    const resetSearch = () => {
        setFilterDate('');
        setFilterCategory([]);
        setEvents([]);
    };

    const canResetSearch = (search: string) => search.length > 0 || filterDate.length > 0 || filterCategory.length > 0;

    async function fetchEvents(s: string) {
        if (!s.length) {
            setEvents([]);
            return;
        }
        const res = await getEvents({ name: s });
        setEvents(res.events);
    }

    return (
        <SearchPageComponent
            title={t('your-search')}
            displayResetSearch={canResetSearch}
            linkBack={() => ({ to: { pathname: '/events/agenda' } })}
            linkSearchEnd={(s) => ({ to: { pathname: '/events/agenda', state: { name: s } } })}
            onSearchChange={(s) => fetchEvents(s)}
            onResetSearch={resetSearch}
        >
            {events.length === 0 ? (
                <>
                    <IonContent>
                        <div className="text-l font-bold typo-h2 mt-block-innerMobile md:mt-block-inner">
                            {t('planning')}
                        </div>
                        <div className="mt-[25px]">
                            <button
                                className="eldo-button block cursor-pointer typo-b1 regular"
                                onClick={() => document.getElementById('date-target')?.click()}
                            >
                                {filterDate ? moment(filterDate).format('DD/MM/YYYY') : t('pick_up_date')}
                            </button>
                            <IonDatetimeButton
                                id="datetimebtn"
                                datetime="datetime"
                                className="hidden"
                            >
                                <div
                                    slot="date-target"
                                    id="date-target"
                                />
                            </IonDatetimeButton>

                            <IonModal keepContentsMounted>
                                <IonDatetime
                                    // @ts-ignore
                                    onIonChange={(e) => setFilterDate(e.target.value)}
                                    color="light"
                                    showDefaultButtons
                                    id="datetime"
                                    mode="md"
                                />
                            </IonModal>
                        </div>

                        {categories?.length ? (
                            <>
                                <div className="text-l font-bold typo-h2 mt-block-innerMobile md:mt-block-inner">
                                    {t('type')}
                                </div>
                                <div className="mt-[25px] [&>*]:pl-[var(--padding-end)] -mx-[var(--padding-end)]">
                                    <ButtonGroup
                                        noFirstSelect
                                        color="tertiary"
                                        onChange={handleTypeChange}
                                        options={categories}
                                        fullWidthOnMobile={false}
                                        className="flex-wrap"
                                    />
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </IonContent>
                    <IonFooter className="ion-padding">
                        <Link
                            to={{ pathname: '/events/agenda', state: { category: filterCategory, date: filterDate } }}
                        >
                            <Button
                                forceHoverEffectActive
                                value={t('filter')}
                            />
                        </Link>
                    </IonFooter>
                </>
            ) : (
                <IonContent>
                    <div className="mt-[30px]">
                        {events.map((event, index) => (
                            <Link to={`/events/agenda/${event.id as unknown as string}`}>
                                <div className="flex gap-[16px] items-center">
                                    <img
                                        className="w-[45px] h-[57px] object-cover rounded-[10px]"
                                        src={event.imageUrl}
                                        alt=""
                                    />
                                    <div>
                                        <div className="line-clamp-1">{event.name}</div>
                                        <div>{moment(event.startDate).format('DD.MM.YY')}</div>
                                    </div>
                                </div>
                                <div className="my-[16px] border-t border-solid border-[#555] w-full" />
                            </Link>
                        ))}
                    </div>
                </IonContent>
            )}
        </SearchPageComponent>
    );
}
