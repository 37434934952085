/* eslint-disable react/no-danger */
import { IonCol, IonRow } from '@ionic/react';

import Card from 'components/Card';
import useTrafficInfo from 'features/trafficInfo/useTrafficInfo';
import TrafficSwiper from 'features/trafficInfo/TrafficSwiper';
import HorizontalMediumCard from 'components/HorizontalMediumCard';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Article } from 'types';
import CustomMap from 'components/CustomMap';
import useAds from 'hooks/useAds';
import AdComponent from 'components/Ads/AdComponent';
import useAuth from 'features/auth/useAuth';
import usePermutiveArticle from 'hooks/usePermutive';
import { generateUniqueId } from 'lib/utils';
import { CalendarIcon, LocationIcon, OrnamentIcon } from '../components/Icons';
import useEventDetail from '../features/event/hooks/useEventDetail';
import useResponsive from '../features/responsive/responsive';
import ArticlePageComponent, {
    getThumbnail,
    getThumbnailForFilmSortien,
    getThumbnailForHorizontalArticleList,
} from '../components/core/ArticlePageComponent';
import Divider from '../components/core/Divider';
import Section from '../components/core/Section';
import SanitizeHtmlComponent from '../components/core/SanitizeHtmlComponent';
import { doesTagContain } from './NewsArticle';
import PageNoData from './PageNoData';

export default function EventArticle({ isCompactView }: EventArticleProps) {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { isAuthenticated } = useAuth();

    const { event, isLoading, error } = useEventDetail(id);

    const { ads } = useAds({ page: 'event-detail', articleId: id, audio: true });
    const { trafficInfos } = useTrafficInfo(id);

    const { IsMobile, IsDesktop } = useResponsive();

    const wordsCounter =
        event?.description === 'string'
            ? event?.description?.trim().replace(/\s+/g, ' ').split(' ')
            : event?.description?.fr.trim().replace(/\s+/g, ' ').split(' ') ||
              event?.description?.en.trim().replace(/\s+/g, ' ').split(' ') ||
              event?.description?.lu.trim().replace(/\s+/g, ' ').split(' ') ||
              0;

    usePermutiveArticle({
        audioContent: false,
        videoContent: false,
        photoGallery: false,
        authors: [],
        modifiedAt: new Date('1.1.1970'),
        publishedAt: new Date('1.1.1970'),
        tags: event?.genre ?? [],
        wordCount: wordsCounter.length,
        send: !isLoading,
    });

    if (error) {
        return <PageNoData />;
    }

    // "transform translate-x-0" is here to attach fixed position to this div (for the floating button)
    return (
        <ArticlePageComponent
            isLoading={isLoading}
            pageClassName="transform translate-x-0"
            description={[
                <SanitizeHtmlComponent>
                    {event?.description === 'string'
                        ? event?.description
                        : event?.description?.fr || event?.description?.en || event?.description?.lu || ''}
                </SanitizeHtmlComponent>,
            ]}
            inpageAdUrl={ads && ads?.data?.filter((item) => item.container === 'inpage')[0]?.url}
            title={event?.name ?? ''}
            image={{ src: event?.imageUrl ?? '', alt: event?.name ?? '' }}
            imgOrVideoSectionClassName="max-md:rounded-t max-md:-mt-[10px]"
            ornamentBlueMobileOverlap={false}
            shareBtnProps={{ url: `${process.env.REACT_APP_FRONTEND_URL || ''}${window.location.pathname}` }}
            tags={
                event?.genre.map((name) => ({
                    name,
                    publicTag: name,
                    tag: name,
                })) || []
            }
            tagsWithLinkProps={event?.genre.map((name) => ({
                name,
                linkProps: { to: { pathname: '/events/agenda', state: { category: { id: name } } } },
            }))}
            slotRightTags={
                <div
                    className={`w-fit text-right whitespace-nowrap ${
                        IsMobile ? 'bg-light-background rounded p-[15px]' : ''
                    }`}
                >
                    {event?.startHour.slice(0, -3)} - {event?.startHour.slice(0, -3)}
                </div>
            }
            slotDescriptionRight={
                ads && (
                    <AdComponent
                        hasSpacings={false}
                        ad={ads.data[1]}
                    />
                )
            }
            slotStart={ads && <AdComponent ad={ads.data[0]} />}
            slotEndFirstSection={
                <div className={!IsMobile ? 'flex flex-row' : ''}>
                    <div className={`flex ${!IsMobile ? 'items-center gap-[30px] ' : 'flex-col gap-[10px]'}`}>
                        <div className="flex flex-row">
                            <LocationIcon />
                            <p className="self-center ml-4 typo-b2 regular">{event?.city}</p>
                        </div>
                        <div className="flex flex-row">
                            <CalendarIcon />
                            <div className="self-center ml-4 typo-b2 regular">
                                {moment(event?.startDate).format('LL')}
                            </div>
                        </div>
                    </div>
                </div>
            }
            slotEnd={
                <>
                    <Divider />
                    {ads && <AdComponent ad={ads.data[3]} />}

                    <Section>
                        <IonRow>
                            <IonCol
                                className="ml-auto"
                                size-xs={12}
                            >
                                <Card className="flex-col cut-off-corner">
                                    <div className="z-10 md:px-[30px] md:py-[20px]">
                                        <OrnamentIcon className="absolute top-0 right-0 w-24" />
                                        <div className="grid gap-[20px]">
                                            <h2 className="typo-h2 bold font-semibold">{t('good_to_know')}</h2>

                                            <p className="z-10 typo-b2 regular">
                                                {t('event_booking_url', { url: event?.url })}
                                            </p>
                                            <div className="grid md:grid-cols-3">
                                                <a
                                                    href="https://www.echo.lu/en/organiser"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    className="eldo-button text-[#333333] bg-white grid place-items-center cursor-pointer typo-b1 regular"
                                                >
                                                    {t('book_your_place')}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Section>

                    <Section>
                        <IonRow>
                            <IonCol size-xs={12}>
                                <CustomMap
                                    className="max-lg:min-w-full lg:min-w-[730px] w-fit rounded"
                                    height={250}
                                    lat={event?.geoLat || 0}
                                    lon={event?.geoLng || 0}
                                />
                            </IonCol>
                        </IonRow>
                    </Section>

                    <Divider />

                    {trafficInfos?.recommendedNews?.length ? (
                        <TrafficSwiper
                            key={generateUniqueId()}
                            swiperEnabled
                            title={t('recommended_for_you')}
                            items={trafficInfos?.recommendedNews}
                            renderItem={(item: Article, index) => (
                                <Link
                                    to={`/aktuell/news/a/${item.id}`}
                                    className="block"
                                    key={index}
                                >
                                    <HorizontalMediumCard
                                        className="min-h-[170px] md:min-h-[200px]"
                                        key={index}
                                        title={item.title}
                                        thumbnailUrl={
                                            doesTagContain(item.tags, 'master_filmsortien')
                                                ? getThumbnailForFilmSortien(item)
                                                : getThumbnailForHorizontalArticleList(item)
                                        }
                                        date={item.date}
                                        tags={item.tags}
                                        truncated
                                        imageCover={item.header?.listing_news ? 'object-fit' : 'object-cover'}
                                    />
                                </Link>
                            )}
                        />
                    ) : (
                        ''
                    )}
                    {ads && isAuthenticated && <AdComponent ad={ads.data[4]} />}
                    {!!trafficInfos?.mostReadNews.length && (
                        <TrafficSwiper
                            key={generateUniqueId()}
                            swiperEnabled
                            title={t('most_read')}
                            items={trafficInfos.mostReadNews}
                            renderItem={(item: Article, index) => (
                                <Link
                                    to={`/aktuell/news/a/${item.id}`}
                                    className="block overflow-hidden"
                                    key={index}
                                >
                                    <HorizontalMediumCard
                                        key={index}
                                        title={item.title}
                                        thumbnailUrl={getThumbnailForHorizontalArticleList(item)}
                                        date={item.date}
                                        onClick={() => history.push(`/aktuell/news/a/${item.id}`)}
                                        truncated
                                        tags={item.tags}
                                        imageCover={item.header?.listing_news ? 'object-fit' : 'object-cover'}
                                    />
                                </Link>
                            )}
                        />
                    )}
                    {ads && !isAuthenticated && <AdComponent ad={ads.data[4]} />}
                    {ads && isAuthenticated && <AdComponent ad={ads.data[5]} />}

                    {trafficInfos?.mightLikeNews?.length ? (
                        <TrafficSwiper
                            key={generateUniqueId()}
                            swiperEnabled
                            title={t('You_might_like')}
                            items={trafficInfos?.mightLikeNews}
                            renderItem={(item: Article, index) => (
                                <HorizontalMediumCard
                                    key={index}
                                    title={item.title}
                                    thumbnailUrl={getThumbnailForHorizontalArticleList(item)}
                                    date={item.date}
                                    tags={item.tags}
                                    className="w-[100%]"
                                    truncated
                                    imageCover={item.header?.listing_news ? 'object-fit' : 'object-cover'}
                                />
                            )}
                        />
                    ) : (
                        ''
                    )}
                </>
            }
        />
    );
}

type EventArticleProps = {
    isCompactView?: boolean;
};

EventArticle.defaultProps = {
    isCompactView: false,
};
