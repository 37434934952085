/* eslint-disable no-nested-ternary */
import { IonGrid } from '@ionic/react';
import ButtonGroup, { type Option } from 'components/ButtonGroup';
import AdComponent from 'components/Ads/AdComponent';
import PageLayout from 'components/PageLayout';
import Title from 'components/Title';
import Section from 'components/core/Section';
import CinemaFilters from 'features/cinema/hooks/components/CinemaFilters';
import MovieCard from 'features/cinema/hooks/components/MovieCard';
import useCinemasList from 'features/cinema/hooks/useCinemasList';
import useMovieByCinema from 'features/cinema/hooks/useMoviesByCinema';
import useResponsive from 'features/responsive/responsive';
import AdRightBlock from 'components/Ads/AdRightBlock';
import useAds from 'hooks/useAds';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Cinema, WeekDates } from 'types';
import LoadingIndicator from 'components/LoadingIndicator';
import MovieHorizontalCard from 'features/cinema/hooks/components/MovieHorizontalCard';
import CardListing from 'components/core/CardListing';
import useCinemaDateRanges from 'features/cinema/hooks/useCinemaDateRanges';
import CinemaNewsPage from './CinemaNewsPage';
import CinemaMovieReleases from './CinemaMovieReleases';

export default function CinemaPage() {
    const { IsMobile } = useResponsive();
    const { data: cinemasResponse, isLoading } = useCinemasList();
    const { t } = useTranslation();
    const [chosenTabByHash, setChosenTabByHash] = useState<any>(undefined);
    const [currentCateg, setCurrentCateg] = useState<Option>({
        id: 'news',
        name: t('cinema.categories.news'),
    });
    const [filterCinema, setFilterCinema] = useState<Cinema>();
    const [filterDates, setFilterDates] = useState<WeekDates>();
    const [filterSearch, setFilterSearch] = useState<string>();
    const { data: moviesByCinemaResponse } = useMovieByCinema({
        id: filterCinema?.id,
        startDate: filterDates?.start,
        endDate: filterDates?.end,
        title: filterSearch,
    });
    const { data: sessionsOptions } = useCinemaDateRanges();
    const isCategCinemas = useMemo(() => currentCateg.id === 'cinema', [currentCateg]);
    const { ads } = useAds({ page: 'cinema', reloadOnChangeString: currentCateg.name });
    const cinemaCateg: Option[] = [
        {
            id: 'news',
            name: t('cinema.categories.news'),
        },
        {
            id: 'releases_movies',
            name: t('cinema.categories.releases_movies'),
        },
        {
            id: 'week_movies',
            name: t('cinema.categories.week_movies'),
        },
        {
            id: 'cinema',
            name: t('cinema.categories.cinema'),
        },
    ];

    useEffect(() => {
        if (!chosenTabByHash) {
            setChosenTabByHash(cinemaCateg[0]);
            cinemaCateg.forEach((tab, index) => {
                if (decodeURIComponent(window.location.hash.substring(1)) === tab.name) {
                    setCurrentCateg(tab);
                    setChosenTabByHash(tab);
                }
            });
        }
    }, []);

    function CinemaContent({ content }: CinemaContentProps) {
        let cinemaContent;

        switch (content) {
            case 'news':
                cinemaContent = <CinemaNewsPage ads={ads} />;
                break;
            case 'releases_movies':
                cinemaContent = <CinemaMovieReleases ads={ads} />;
                break;
            case 'week_movies':
                cinemaContent = (
                    <CinemaNewsPage
                        filmsOfTheWeek
                        ads={ads}
                    />
                );
                break;
            default:
                cinemaContent = !isLoading ? (
                    <AdRightBlock ad={ads?.data?.[1]}>
                        <CardListing
                            oneColumn={filterDates != null}
                            isVerticalCards
                        >
                            {(moviesByCinemaResponse || [])?.map((m) => (
                                <Link
                                    to={`/movies/${m.movie?.id}`}
                                    key={m.movie?.id}
                                >
                                    {filterDates != null ? (
                                        <MovieHorizontalCard
                                            sessionsOptions={sessionsOptions ?? []}
                                            sessions={m.sessions}
                                            className="w-full h-full max-w-[825px]"
                                            lang="en"
                                            movie={m.movie}
                                            firstDate={filterDates.start}
                                        />
                                    ) : (
                                        <MovieCard
                                            className="w-full h-full"
                                            lang="en"
                                            movie={m.movie}
                                        />
                                    )}
                                </Link>
                            ))}
                        </CardListing>
                        {ads && <AdComponent ad={ads.data[2]} />}
                    </AdRightBlock>
                ) : (
                    <LoadingIndicator />
                );
                break;
        }
        return cinemaContent;
    }

    return (
        <PageLayout
            title={t('cinema.cinema')}
            hasBackButton
        >
            <IonGrid fixed>
                {ads && <AdComponent ad={ads.data[0]} />}
                <Section>
                    <Title
                        main
                        className="max-lg:hidden mb-blockInner"
                        title={t('cinema.cinema')}
                    />
                    <ButtonGroup
                        value={chosenTabByHash}
                        onChange={(opt) => setCurrentCateg(opt as Option)}
                        options={cinemaCateg}
                        noFirstSelect
                    />
                </Section>
                {isCategCinemas ? (
                    <CinemaFilters
                        onSearch={setFilterSearch}
                        cinemas={cinemasResponse}
                        cinema={filterCinema}
                        onCinemaChange={setFilterCinema}
                        onDateChange={setFilterDates}
                        className="mb-blockInnerMobile md:mb-blockInner"
                        sessionsOptions={sessionsOptions}
                    />
                ) : (
                    ''
                )}
                <Section
                    title={isCategCinemas && filterCinema != null && !IsMobile ? filterCinema.fullName : undefined}
                >
                    <CinemaContent content={currentCateg.id} />
                </Section>
            </IonGrid>
        </PageLayout>
    );
}

type CinemaContentProps = {
    content: string | number;
};
