import { IonRow } from '@ionic/react';
import { useEffect, useRef } from 'react';
import moment from 'moment';
import { AdResponse } from 'features/advertising/generateAdPayload';
import useCinemaBoxOfficeArticles from 'features/cinema/hooks/useCinemaBoxOfficeArticles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from 'components/LoadingIndicator';
import CardVertical from 'components/core/CardVertical';
import { getThumbnailForHorizontalArticleList } from 'components/core/ArticlePageComponent';
import CardListingChunksWithAds from 'components/CardListingChunksWithAds';
import AdComponent from 'components/Ads/AdComponent';

function CinemaMovieReleases({ isCompactView, ads }: CinemaMovieReleasesProps) {
    const { t } = useTranslation();
    const cinemaBoxOffice = useCinemaBoxOfficeArticles();

    const observerTarget = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    cinemaBoxOffice.loadMore();
                }
            },
            { threshold: 1 },
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget, cinemaBoxOffice]);

    return !cinemaBoxOffice.isLoading ? (
        <>
            <CardListingChunksWithAds
                className="mb-blockMobile md:mb-block"
                adsBetween={ads ? [ads.data[1]] : []}
                data={cinemaBoxOffice?.list || []}
                adNativeClassName="!h-[200px] overflow-hidden"
            >
                {(m) => (
                    <Link
                        to={`/kino/articles/${m.id as unknown as string}`}
                        key={m.id}
                    >
                        <CardVertical
                            className="w-full h-full"
                            tags={m.tags}
                            title={m.title}
                            footerSlot={moment(m.date).format('DD.MM.YY')}
                            thumbnailUrl={getThumbnailForHorizontalArticleList(m)}
                            imgSize="small"
                            hasWavePattern={false}
                            imageCover={m.header?.listing_news ? 'object-fit' : 'object-cover'}
                        />
                    </Link>
                )}
            </CardListingChunksWithAds>
            {cinemaBoxOffice.canLoadMore ? (
                <IonRow className="mb-blockMobile md:mb-block">
                    <div
                        className="m-auto h-4"
                        ref={observerTarget}
                    />
                </IonRow>
            ) : (
                ''
            )}
            {ads && !cinemaBoxOffice.canLoadMore && !cinemaBoxOffice.isLoading && <AdComponent ad={ads.data[2]} />}
        </>
    ) : (
        <LoadingIndicator />
    );
}

type CinemaMovieReleasesProps = {
    isCompactView?: boolean;
    ads: AdResponse | undefined;
};

CinemaMovieReleases.defaultProps = {
    isCompactView: false,
};

export default CinemaMovieReleases;
