import { IonCol, IonGrid, IonLoading, IonRouterLink, IonRow, useIonToast } from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Charts } from 'features/charts/types';
import useCharts from 'features/charts/hooks/useCharts';
import { useParams } from 'react-router';
import { Preferences } from '@capacitor/preferences';
import { stopAllAudio } from 'lib/utils';

import Card from 'components/Card';
import PlayerButton from 'features/radio/component/player/PlayerButton';
import { SongPositionDown, SongPositionEqual, SongPositionUp } from 'components/Icons';
import useAds from 'hooks/useAds';
import LoadingIndicator from 'components/LoadingIndicator';

import AdComponent from 'components/Ads/AdComponent';
import AdRightBlock from 'components/Ads/AdRightBlock';
import Title from 'components/Title';

import useResponsive from 'features/responsive/responsive';
import WavePatternContainer from 'components/WavePatternContainer';
import { useTranslation } from 'react-i18next';
import useAuth from 'features/auth/useAuth';
import { sendVote } from 'lib/ApiService';
import { PlayerContext, PlayerContextType } from 'features/radio/PlayerContext';
import PageLayout from '../components/PageLayout';
import { TOAST_DURATION } from '../constants';

const chartBreakType: { [key: string]: string } = {
    chartbreaker: 'Chartbreaker',
    dance: 'Dance & Party Charts',
};

interface SongStatus extends Charts {
    isPlaying: boolean;
    votes: number;
}

interface PersistedSongStatus {
    lastUpdate: string;
    songStatus: SongStatus[];
    totalVoteCounter: number;
}

function getSongPositionStatusIcon(song: Charts, t: any) {
    if (song.position > song.lastPosition) {
        return (
            <div className="rounded-full bg-[var(--eldo-primary)] mr-2 w-[30px] h-[30px] grid place-items-center">
                <SongPositionDown />
            </div>
        );
    }

    if (song.position < song.lastPosition) {
        return (
            <div className="rounded-full bg-[#67ce67] mr-2 w-[30px] h-[30px] grid place-items-center">
                <SongPositionUp />
            </div>
        );
    }

    return <SongPositionEqual className="mt-1 mr-2" />;
}

function getPositionStatus(song: Charts, t: any) {
    return (
        <div className="flex flex-row ml-1 mb-4 mt-0 items-center typo-b2">
            {getSongPositionStatusIcon(song, t)} <strong className="mr-2 typo-b1 font-semibold">{song.position}</strong>
            {song.lastPosition > 50 ? (
                <div className="mr-2 font-medium">({t('new')})</div>
            ) : (
                `(Last weekend ${song.lastPosition})`
            )}
        </div>
    );
}

export function getCurrentHourAndDate() {
    const now = new Date();
    return now.getHours().toString() + now.getDate().toString();
}

async function saveSongVotesLocally(chartName: string, songStatus: SongStatus[], totalVoteCounter: number) {
    await Preferences.set({
        key: chartName,
        value: JSON.stringify({
            lastUpdate: getCurrentHourAndDate(),
            songStatus,
            totalVoteCounter,
        }),
    });
}

export default function ChartBreakerSongList() {
    const { ads } = useAds({ page: 'chartsong' });
    const { chart } = useParams<{ chart: string }>();
    const { data, isLoading } = useCharts(chart);
    const [songStatus, setSongStatus] = useState<SongStatus[]>([]);
    const [totalVoteCounter, setTotalVoteCounter] = useState<number>(0);
    const { IsMobile, IsDesktop } = useResponsive();
    const { t } = useTranslation();
    const { isAuthenticated } = useAuth();
    const [present] = useIonToast();
    const [isSeendingVote, setIsSendingVote] = useState(false);
    const { playerDispatch } = useContext<PlayerContextType | null>(PlayerContext) as PlayerContextType;

    const setIsPlayingState = (id: string, isPlaying?: boolean) => {
        const updatedVotes = songStatus.map((song: SongStatus) => {
            if (song.id === id) {
                const newIsPlayingState = isPlaying != null ? isPlaying : !song.isPlaying;
                // @ts-ignore
                const audioElement: HTMLAudioElement = document.getElementById(id);
                if (!newIsPlayingState) {
                    audioElement?.pause();
                } else {
                    stopAllAudio(audioElement);
                    playerDispatch({ type: 'PAUSE' });
                    audioElement?.play();
                }
                return { ...song, isPlaying: newIsPlayingState };
            }
            return song;
        });
        setSongStatus(updatedVotes);
    };

    const updateVoteStatus = (id: string) => {
        if (isSeendingVote) {
            return;
        }
        const newSongStatus = [...songStatus];
        const song = newSongStatus.find((songItem) => songItem.id === id);

        if (song?.id === id && song.votes < 5 && totalVoteCounter < 20) {
            setIsSendingVote(true);
            const response = sendVote(chart, song.position);
            response
                .then((responseData) => {
                    if (responseData.ok) {
                        present({
                            color: 'success',
                            duration: TOAST_DURATION,
                            message: t('chartbreaker.voteSuccessfuly'),
                        });
                        setTotalVoteCounter(totalVoteCounter + 1);
                        song.votes += 1;
                        setSongStatus(newSongStatus);
                        saveSongVotesLocally(chart, newSongStatus, totalVoteCounter);
                    }
                })
                .catch((e) => {
                    const message: string =
                        e.error || e.response?.data?.error || e.response?.message || e.message || 'Error';
                    present({ message, duration: TOAST_DURATION, color: 'danger' });
                })
                .finally(() => {
                    setIsSendingVote(false);
                });
        } else {
            present({ message: t('chartbreaker.maxVotesError'), duration: TOAST_DURATION, color: 'danger' });
        }
    };

    const populateSongs = async () => {
        const serializedData = await Preferences.get({ key: chart });
        let savedSongs: PersistedSongStatus | null = null;
        if (serializedData && serializedData.value) {
            savedSongs = JSON.parse(serializedData?.value ?? '');
        }
        const updatedSongs: SongStatus[] =
            data?.map((song) => {
                if (
                    savedSongs?.songStatus &&
                    savedSongs.songStatus.some((savedSong: SongStatus) => savedSong.id === song.id) &&
                    savedSongs.lastUpdate === getCurrentHourAndDate()
                ) {
                    return savedSongs.songStatus.filter((savedSong: SongStatus) => savedSong.id === song.id)[0];
                }
                return { isPlaying: false, votes: 0, ...song };
            }) || [];
        setSongStatus(updatedSongs);
        const sumOfExistingVotes = updatedSongs.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.votes;
        }, 0);
        setTotalVoteCounter(sumOfExistingVotes);

        saveSongVotesLocally(chart, updatedSongs, totalVoteCounter);
    };

    useEffect(() => {
        if (!isLoading) {
            populateSongs();
        }
    }, [data, isLoading, chart]);

    return (
        <PageLayout
            title={(IsMobile && chartBreakType[chart]) || ''}
            hasBackButton
            backPath="/musek/charts"
        >
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <IonGrid fixed>
                    {ads && (
                        <AdComponent
                            ad={ads.data[0]}
                            className={IsMobile ? '[&>iframe]:!-mx-[25px] [&>iframe]:!-mt-[30px] !w-screen' : ''}
                        />
                    )}
                    <Title
                        main
                        title={chartBreakType?.[chart] || ''}
                        className="max-md:hidden mb-blockInner"
                    />
                    <WavePatternContainer
                        autoMargin
                        fixed="page"
                        reverse
                        position="top-right"
                        className="mb-blockMobile md:mb-block"
                        component="section"
                    >
                        <IonRow>
                            <IonCol size-xs={12}>
                                <div className="font typo-b2 md:max-w-[850px] mb-blockInnerMobile md:mb-blockInner">
                                    <p className="mb-[25px]">
                                        {chart === 'chartbreaker'
                                            ? 'Lauschtert dat neit Klassement vum Chartbreaker all Samschdeg vun 13h00 bis 17h00 op Eldoradio!'
                                            : 'D’Dance & Party Charts - All Donneschdeg Owend vun 20h00 un op Eldoradio!'}
                                    </p>
                                    {isAuthenticated ? (
                                        <>
                                            <p className="font-semibold">Voting Reegelen :</p>
                                            Dir kënnt wärend enger Stonn maximal 20 Votes ofginn a maximal 5 Mol pro
                                            Lidd voten. All weidere Vote gëtt net matgezielt!
                                            <br />
                                            <div className="mt-block-innerMobile md:mt-block-inner typo-b2">
                                                Dir hutt <strong>{totalVoteCounter} Votes oofgin..</strong>
                                            </div>
                                        </>
                                    ) : (
                                        <div className={`ml-auto flex-column grid-cols-${isAuthenticated ? 4 : 6}`}>
                                            <IonRouterLink
                                                routerDirection="forward"
                                                routerLink="/login"
                                                className={`eldo-button md:w-[500px] md:w-[350px] cursor-pointer typo-b1 grid place-items-center ${
                                                    IsMobile ? 'my-blockMobile md:my-block' : ''
                                                } `}
                                            >
                                                {t('chartbreaker.voteButton')}
                                            </IonRouterLink>
                                        </div>
                                    )}
                                </div>
                            </IonCol>
                        </IonRow>
                        <AdRightBlock ad={ads?.data?.[1]}>
                            {!isLoading &&
                                songStatus?.slice(0, 50).map((song, index) => (
                                    <Card
                                        className="text-left mb-4 flex flex-row md:max-w-[850px]"
                                        key={song.id}
                                    >
                                        <div className="flex flex-col w-full">
                                            {getPositionStatus(song, t)}
                                            <div className="flex flex-row items-center">
                                                <div className="w-18 h-18 mr-4 grid items-center">
                                                    <img
                                                        src={song?.imageUrl || '/assets/img/last_song_image.png'}
                                                        alt={song.title}
                                                        className="aspect-w-1 rounded-lg aspect-h-1 object-cover"
                                                    />
                                                </div>
                                                <div className="h-full w-full">
                                                    <p className="ypo-b1 font-semibold m-1">{song.artist}</p>
                                                    <p className="typo-b2 regular m-1 mt-2">{song.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-[40px]">
                                            {isAuthenticated && (
                                                <section className="mt-2 min-w-[40px] cursor-pointer">
                                                    <div
                                                        onClick={() => updateVoteStatus(song.id)}
                                                        className={`typo-b1 font-medium ${
                                                            IsDesktop
                                                                ? 'bg-white rounded-full w-[45px] h-[45px] p-[10px]'
                                                                : 'bg-white rounded-full w-[35px] h-[35px] p-[7px]'
                                                        } mb-4 text-center grid place-items-center`}
                                                        style={{ color: 'black' }}
                                                    >
                                                        +{song.votes}
                                                    </div>
                                                </section>
                                            )}
                                            <section className="mt-2 min-w-[40px]">
                                                <audio
                                                    onPause={() => setIsPlayingState(song.id, false)}
                                                    autoPlay={false}
                                                    id={song.id}
                                                >
                                                    {' '}
                                                    <source
                                                        src={song.preview}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                                <PlayerButton
                                                    size={IsDesktop ? 'sm' : 'xxs'}
                                                    onClick={() => setIsPlayingState(song.id)}
                                                    type={`${song.isPlaying ? 'pause' : 'play'}`}
                                                />
                                            </section>
                                        </div>
                                    </Card>
                                ))}
                        </AdRightBlock>
                        {!isLoading && songStatus?.slice(50).length ? (
                            <div>
                                <Title
                                    title={t('new_music')}
                                    className="mt-[50px] mb-[25px]"
                                />
                                {songStatus?.slice(50).map((song, index) => (
                                    <Card
                                        className="text-left mb-4 flex flex-row md:max-w-[850px]"
                                        key={song.id}
                                    >
                                        <div className="flex flex-col w-full">
                                            <div className="mr-2 font-bold text-primary mb-[10px] ml-[10px]">
                                                {t('new')}
                                            </div>
                                            <div className="flex flex-row items-center">
                                                <div className="w-18 h-18 mr-4 grid items-center">
                                                    <img
                                                        src={song?.imageUrl || '/assets/img/last_song_image.png'}
                                                        alt={song.title}
                                                        className="aspect-w-1 rounded-lg aspect-h-1 object-cover"
                                                    />
                                                </div>
                                                <div className="h-full w-full">
                                                    <p className="ypo-b1 font-semibold m-1">{song.artist}</p>
                                                    <p className="typo-b2 regular m-1 mt-2">{song.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-[40px]">
                                            {isAuthenticated && (
                                                <section className="mt-2 min-w-[40px] cursor-pointer">
                                                    <div
                                                        onClick={() => updateVoteStatus(song.id)}
                                                        className={`typo-b1 font-medium ${
                                                            IsDesktop
                                                                ? 'bg-white rounded-full w-[45px] h-[45px] p-[10px]'
                                                                : 'bg-white rounded-full w-[35px] h-[35px] p-[7px]'
                                                        } mb-4 text-center grid place-items-center`}
                                                        style={{ color: 'black' }}
                                                    >
                                                        +{song.votes}
                                                    </div>
                                                </section>
                                            )}
                                            <section className="mt-2 min-w-[40px]">
                                                <audio
                                                    onPause={() => setIsPlayingState(song.id, false)}
                                                    autoPlay={false}
                                                    id={song.id}
                                                >
                                                    {' '}
                                                    <source
                                                        src={song.preview}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                                <PlayerButton
                                                    size={IsDesktop ? 'sm' : 'xxs'}
                                                    onClick={() => setIsPlayingState(song.id)}
                                                    type={`${song.isPlaying ? 'pause' : 'play'}`}
                                                />
                                            </section>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        ) : (
                            ''
                        )}
                    </WavePatternContainer>

                    {ads && <AdComponent ad={ads.data[2]} />}
                </IonGrid>
            )}
        </PageLayout>
    );
}
