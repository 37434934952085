import { IonCol, IonGrid, IonRow } from '@ionic/react';
import AdComponent from 'components/Ads/AdComponent';
import useAds from 'hooks/useAds';
import EldoAdComponent from 'components/AdComponent';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Img from 'components/Img';
import useResponsive from 'features/responsive/responsive';
import WavePatternContainer from 'components/WavePatternContainer';
import Title from 'components/Title';
import PageLayout from '../components/PageLayout';
import CardVertical from '../components/core/CardVertical';
import AdRightBlock from '../components/Ads/AdRightBlock';

export default function ChartBreakPage() {
    const { ads } = useAds({ page: 'charts' });
    const { t } = useTranslation();
    const musicNewsBanner = 'assets/img/chartbreaker.png';
    const dancePartyBanner = 'assets/img/dance-party.svg';
    const { IsMobile, IsDesktop } = useResponsive();

    return (
        <PageLayout
            title={(IsMobile && t('Charts')) || ''}
            cssClass="radio-page"
            hasBackButton
        >
            <IonGrid fixed>
                {ads && <AdComponent ad={ads.data[0]} />}
                <WavePatternContainer
                    autoMargin
                    fixed="page"
                    reverse
                    position="top-right"
                    component="section"
                    onlyDesktop
                >
                    <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                        <IonCol>
                            <Title
                                title={t('Charts')}
                                main
                            />
                        </IonCol>
                    </IonRow>
                    <AdRightBlock ad={ads?.data?.[1]}>
                        <div className="grid gap-standardMobile md:gap-standard md:grid-cols-2 h-fit">
                            <Link
                                to="/musek/charts/chartbreaker"
                                className="group/hovercard  bg-light-background rounded-[10px] block"
                            >
                                <CardVertical
                                    thumbnailUrl={musicNewsBanner}
                                    slotTitle={
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: `<strong className='font-semibold'>D’Top 50 </strong>– Dat Bescht national an international, vun iech zesumme gestallt!
                                                 Vote mat a bestëmmt, wien déi nächst Nummer 1 an den Charts zu Lëtzebuerg gëtt.`,
                                            }}
                                        />
                                    }
                                    imgSize="extrasmall"
                                    displayAllDescription
                                    hasWavePattern={false}
                                    imageCover="object-fit"
                                />
                            </Link>
                        </div>
                    </AdRightBlock>

                    {ads && (
                        <AdComponent
                            ad={ads.data[2]}
                            className="mt-[50px]"
                        />
                    )}
                </WavePatternContainer>
            </IonGrid>
        </PageLayout>
    );
}
