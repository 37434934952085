/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HorizontalMediumCard from 'components/HorizontalMediumCard';
import { Article, Game, RoadNews } from 'types';
import Card from 'components/Card';
import moment from 'moment';
import { generateUniqueId, isNativeAd } from 'lib/utils';
import AdComponent from 'components/Ads/AdComponent';
import useResponsive from 'features/responsive/responsive';
import { getThumbnailForFilmSortien, getThumbnailForHorizontalArticleList } from 'components/core/ArticlePageComponent';
import { doesTagContain } from 'pages/NewsArticle';
import useTrafficInfo from './useTrafficInfo';
import TrafficFavoriteCams from './TrafficFavoriteCams';
import TrafficSwiper from './TrafficSwiper';
import { transformTagsIntoObject } from './TrafficNews';

export default function TrafficDailyRoute() {
    const { trafficInfos, lastRoadNews } = useTrafficInfo();
    const { t } = useTranslation();
    const history = useHistory();
    const { IsMobile } = useResponsive();

    return (
        <div>
            <TrafficFavoriteCams />

            {!!lastRoadNews.length && (
                <TrafficSwiper
                    swiperEnabled={!IsMobile}
                    titleHtml={`${t('last_road_news')} <div><img src="/assets/img/ACL.png"/"></div>`}
                    path="/traffic-info/news"
                    items={lastRoadNews}
                    className=""
                    renderItem={(item: RoadNews, index) => (
                        <HorizontalMediumCard
                            key={generateUniqueId()}
                            title={item.title}
                            date={item.date}
                            displayTime
                            tags={transformTagsIntoObject(item.tags)}
                            clickable={false}
                            noImage
                        />
                    )}
                />
            )}

            {IsMobile && !!trafficInfos?.games.length && (
                <TrafficSwiper
                    key={generateUniqueId()}
                    swiperEnabled
                    title={t('games')}
                    items={trafficInfos.games}
                    renderItem={(item: Game) => (
                        <Link
                            key={item.id}
                            to={`/game/${item.id}`}
                            className="w-full"
                        >
                            <Card
                                key={item.id}
                                className="w-full h-[135px]"
                            >
                                <div className="text-left">
                                    <p className="font-medium mb-4 line-clamp-1">{item.name}</p>
                                    <p className="text-md">{item.location}</p>
                                    <p className="text-md">{moment(item.dateTime).format('DD.MM.YY')}</p>
                                </div>
                            </Card>
                        </Link>
                    )}
                />
            )}

            {IsMobile && !!trafficInfos?.recommendedNews.length && (
                <TrafficSwiper
                    swiperEnabled
                    title={t('recommended_for_you')}
                    items={trafficInfos?.recommendedNews}
                    renderItem={(item: Article, index) =>
                        !isNativeAd(item) ? (
                            <HorizontalMediumCard
                                key={generateUniqueId()}
                                title={item.title}
                                thumbnailUrl={
                                    doesTagContain(item.tags, 'master_filmsortien')
                                        ? getThumbnailForFilmSortien(item)
                                        : getThumbnailForHorizontalArticleList(item)
                                }
                                date={item.date}
                                tags={item.tags}
                                imageCover={item.header?.listing_news ? 'object-fit' : 'object-cover'}
                                className="max-h-[175px]"
                            />
                        ) : (
                            item && (
                                <AdComponent
                                    ad={item}
                                    hasSpacings={false}
                                    className="!h-[175px] overflow-hidden"
                                />
                            )
                        )
                    }
                />
            )}

            {IsMobile && !!trafficInfos?.mostReadNews.length && (
                <TrafficSwiper
                    swiperEnabled
                    title={t('most_read')}
                    items={trafficInfos.mostReadNews}
                    renderItem={(item: Article, index) =>
                        !isNativeAd(item) ? (
                            <Link
                                to={`/aktuell/news/a/${item.id}`}
                                className="block overflow-hidden h-full"
                                key={generateUniqueId()}
                            >
                                <HorizontalMediumCard
                                    className="max-h-[175px]"
                                    key={index}
                                    title={item.title}
                                    thumbnailUrl={
                                        doesTagContain(item.tags, 'master_filmsortien')
                                            ? getThumbnailForFilmSortien(item)
                                            : getThumbnailForHorizontalArticleList(item)
                                    }
                                    date={item.date}
                                    onClick={() => history.push(`/aktuell/news/a/${item.id}`)}
                                    tags={item.tags}
                                    imageCover={item.header?.listing_news ? 'object-fit' : 'object-cover'}
                                />
                            </Link>
                        ) : (
                            item && (
                                <AdComponent
                                    ad={item}
                                    hasSpacings={false}
                                    className="!h-[175px] overflow-hidden"
                                />
                            )
                        )
                    }
                />
            )}
        </div>
    );
}
