import { IonGrid } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import Title from 'components/Title';

import useAds from 'hooks/useAds';
import usePodcastsOrReplays from 'features/podcastsReplays/hooks/usePodcastsOrReplays';

import AdComponent from 'components/Ads/AdComponent';
import InPage from 'components/Ads/Inpage';
import Section from 'components/core/Section';
import { PodcastAndReplayPageData, PodcastOrReplay } from 'types';
import LoadingIndicator from 'components/LoadingIndicator';
import { AdData } from 'features/advertising/generateAdPayload';
import PageLayout from '../components/PageLayout';
import 'swiper/css';
import CardVertical from '../components/core/CardVertical';
import CarouselWithAds from '../components/Carousel/CarouselWithAds';
import AdRightBlock from '../components/Ads/AdRightBlock';

export const podcastHiddenIds = [
    '20216', // Schaarfen Ueleg
    '20965', // Juuubel
    '23589', // Intensiv primitiv
];

export default function PodcastAndReplays() {
    const { ads } = useAds({ page: 'podcast' });
    const { t } = useTranslation();
    const { podcastAndReplaysData, isLoading } = usePodcastsOrReplays();
    const localPodcastAndReplayData = localStorage.getItem('podcastAndReplay') ?? '{}';
    const localPodcastAndReplayParsed = JSON.parse(localPodcastAndReplayData);
    const [podcastReplays, setPodcastReplays] = useState<PodcastAndReplayPageData | null>(null);

    useEffect(() => {
        if (!isLoading) {
            if (podcastAndReplaysData) {
                localStorage.setItem('podcastAndReplay', JSON.stringify(podcastAndReplaysData));
                setPodcastReplays(podcastAndReplaysData);
            }
        }
    }, [podcastAndReplaysData]);

    useEffect(() => {
        if (localPodcastAndReplayData !== '{}') {
            setPodcastReplays(localPodcastAndReplayParsed);
        }
    }, []);

    return (
        <PageLayout
            title="Podcast & replay"
            hasBillboard
        >
            {podcastReplays ? (
                <IonGrid fixed>
                    {ads && <AdComponent ad={ads.data[0]} />}
                    <Title
                        main
                        title={t('menu.podcast_replay')}
                        className="mb-blockMobile md:mb-block"
                    />
                    <AdRightBlock ad={ads?.data[1]}>
                        <Section title={t('recently_viewed')}>
                            <CarouselWithAds
                                data={(
                                    (podcastReplays?.recentlyViewed as PodcastOrReplay[] &
                                        (PodcastOrReplay | AdData)[]) || []
                                ).filter((p) => !podcastHiddenIds.find((id) => id === p.id))}
                            >
                                {(recentlyViewed: PodcastOrReplay) => (
                                    <Link
                                        to={`/podcast-replay/type/${
                                            recentlyViewed?.type?.toLowerCase() as unknown as string
                                        }/${recentlyViewed.id as unknown as string}`}
                                        className="block h-full w-full"
                                    >
                                        <CardVertical
                                            thumbnailUrl={
                                                recentlyViewed?.poster
                                                    ? `${(recentlyViewed?.poster as unknown as string) ?? ''}`
                                                    : 'cataas.com/cat'
                                            }
                                            title={recentlyViewed.title}
                                            tags={[{ name: 'recentlyviewed', tag: 'recentlyviewed' }]}
                                            imgClassName="!h-[269px] !w-full !object-cover object-top"
                                            footerSlot={
                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        {recentlyViewed.duration
                                                            ? `${parseInt(recentlyViewed.duration, 10)} ${t('sec')}`
                                                            : ''}
                                                    </div>
                                                </div>
                                            }
                                            className="h-full"
                                            imgSize="medium"
                                        />
                                    </Link>
                                )}
                            </CarouselWithAds>
                        </Section>
                        <Section
                            title={t('podcasts')}
                            path="/podcast-replay/category/podcast"
                        >
                            <CarouselWithAds
                                // @ts-ignore
                                data={(podcastReplays?.podcast || []).filter(
                                    // @ts-ignore
                                    // eslint-disable-next-line eqeqeq
                                    (p) => !podcastHiddenIds.find((id) => id == p.id),
                                )}
                                adNativeClassName="h-[220px] overflow-hidden"
                            >
                                {(podcast: PodcastOrReplay) => (
                                    <Link
                                        to={`/podcast-replay/type/${podcast.type.toLowerCase()}/${
                                            podcast.id as unknown as string
                                        }`}
                                        className="block h-full w-full"
                                    >
                                        <CardVertical
                                            thumbnailUrl={
                                                podcast?.poster ? `${podcast?.poster ?? ''}` : 'cataas.com/cat'
                                            }
                                            title={podcast.title}
                                            tags={[{ name: 'Podcasts', tag: 'Podcasts' }]}
                                            footerSlot={
                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        {podcast.duration
                                                            ? `${parseInt(podcast.duration, 10)} ${t('sec')}`
                                                            : ''}
                                                    </div>
                                                </div>
                                            }
                                            className="h-full"
                                            imgSize="medium"
                                        />
                                    </Link>
                                )}
                            </CarouselWithAds>
                        </Section>
                        {podcastReplays?.digitalMeetings?.replays?.length ? (
                            <Section title={t('digital_meetings')}>
                                <CarouselWithAds
                                    adNativeClassName="h-[220px] overflow-hidden"
                                    // @ts-ignore
                                    data={podcastReplays?.digitalMeetings.replays || []}
                                >
                                    {(digitalMeetings: PodcastOrReplay) => (
                                        <Link
                                            to={`/podcast-replay/type/${digitalMeetings.type.toLowerCase()}/${
                                                digitalMeetings.id as unknown as string
                                            }`}
                                            className="block h-full w-full"
                                        >
                                            <CardVertical
                                                thumbnailUrl={
                                                    digitalMeetings?.poster
                                                        ? `${digitalMeetings?.poster ?? ''}`
                                                        : 'cataas.com/cat'
                                                }
                                                title={digitalMeetings.title}
                                                tags={digitalMeetings.tags}
                                                footerSlot={
                                                    <div className="flex justify-between items-center">
                                                        <div>
                                                            {digitalMeetings.duration
                                                                ? `${parseInt(digitalMeetings.duration, 10)} ${t(
                                                                      'sec',
                                                                  )}`
                                                                : ''}
                                                        </div>
                                                    </div>
                                                }
                                                imgClassName="!h-[269px] !w-full !object-cover object-top"
                                                className="h-full"
                                                imgSize="medium"
                                                hasPlayBtnOnTopOfImg
                                            />
                                        </Link>
                                    )}
                                </CarouselWithAds>
                            </Section>
                        ) : (
                            ''
                        )}

                        {ads && <AdComponent ad={ads.data[2]} />}
                        <Section
                            title={t('eldo4real')}
                            path="/podcast-replay/category/eldo4real"
                        >
                            <CarouselWithAds
                                adNativeClassName="h-[220px] overflow-hidden"
                                // @ts-ignore
                                data={podcastReplays?.eldo4real?.replays || []}
                            >
                                {(eldo4real: PodcastOrReplay) => (
                                    <Link
                                        to={`/podcast-replay/type/eldo4real/${eldo4real.id as unknown as string}`}
                                        className="block h-full w-full"
                                    >
                                        <CardVertical
                                            thumbnailUrl={
                                                eldo4real?.poster ? `${eldo4real?.poster ?? ''}` : 'cataas.com/cat'
                                            }
                                            imgClassName="!h-[269px] !w-full !object-cover object-top"
                                            title={eldo4real.title}
                                            tags={[{ name: t('eldo4real'), tag: 'Eldo4Real' }]}
                                            footerSlot={
                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        {eldo4real.duration
                                                            ? `${parseInt(eldo4real.duration, 10)} ${t('sec')}`
                                                            : ''}
                                                    </div>
                                                </div>
                                            }
                                            className="h-full"
                                            imgSize="medium"
                                        />
                                    </Link>
                                )}
                            </CarouselWithAds>
                        </Section>

                        {ads && (
                            <InPage
                                adSrc={
                                    ((Array.isArray(ads.data) ? ads?.data : null) || [])?.filter(
                                        (item) => item.container === 'inpage',
                                    )[0]?.url ?? ''
                                }
                                nameOfElementId="podcastInpageId"
                            />
                        )}
                        {podcastReplays?.foodDrinks?.replays?.length ? (
                            <Section
                                title={t('foodDrinks')}
                                path="/podcast-replay/category/foodanddrinks"
                            >
                                <CarouselWithAds
                                    adNativeClassName="h-[220px] overflow-hidden"
                                    // @ts-ignore
                                    data={podcastReplays?.foodDrinks.replays || []}
                                >
                                    {(foodDrinks: PodcastOrReplay) => (
                                        <Link
                                            to={`/podcast-replay/type/${foodDrinks.type.toLowerCase()}/${
                                                foodDrinks.id as unknown as string
                                            }`}
                                            className="block h-full w-full"
                                        >
                                            <CardVertical
                                                thumbnailUrl={
                                                    foodDrinks?.poster
                                                        ? `${foodDrinks?.poster ?? ''}`
                                                        : 'cataas.com/cat'
                                                }
                                                imgClassName="!h-[269px] !w-full !object-cover object-top"
                                                title={foodDrinks.title}
                                                tags={[{ name: 'Food & Drinks', tag: 'Food & Drinks' }]}
                                                footerSlot={
                                                    <div className="flex justify-between items-center">
                                                        <div>
                                                            {foodDrinks.duration
                                                                ? `${parseInt(foodDrinks.duration, 10)} ${t('sec')}`
                                                                : ''}
                                                        </div>
                                                    </div>
                                                }
                                                className="h-full"
                                                imgSize="medium"
                                            />
                                        </Link>
                                    )}
                                </CarouselWithAds>
                            </Section>
                        ) : (
                            ''
                        )}

                        {/* {podcastAndReplaysData?.fashionBeautyLifestyle?.replays?.length ? (
                            <Section
                                title={t('fashionBeautyLifestyle')}
                                path='/podcast-replay/category/fashionBeautyLifestyle'
                            >
                                <CarouselWithAds
                                    // @ts-ignore
                                    data={podcastAndReplaysData?.fashionBeautyLifestyle.replays || []}
                                    adNativeClassName='h-[220px] overflow-hidden'
                                >
                                    {(fashionBeautyLifestyle: PodcastOrReplay) => (
                                        <Link
                                            to={`/podcast-replay/type/${fashionBeautyLifestyle.type.toLowerCase()}/${
                                                fashionBeautyLifestyle.id as unknown as string
                                            }`}
                                            className='block h-full w-full'
                                        >
                                            <CardVertical
                                                thumbnailUrl={
                                                    fashionBeautyLifestyle?.poster
                                                        ? `${fashionBeautyLifestyle?.poster ?? ''}`
                                                        : 'cataas.com/cat'
                                                }
                                                title={fashionBeautyLifestyle.title}
                                                tags={[
                                                    {
                                                        name: 'Fashion & Beauty & Lifestyle',
                                                        tag: 'Fashion & Beauty & Lifestyle'
                                                    }
                                                ]}
                                                footerSlot={
                                                    <div className='flex justify-between items-center'>
                                                        <div>
                                                            {fashionBeautyLifestyle.duration
                                                                ? `${parseInt(fashionBeautyLifestyle.duration, 10)} ${t(
                                                                      'sec'
                                                                  )}`
                                                                : ''}
                                                        </div>
                                                    </div>
                                                }
                                                className='h-full'
                                                imgSize='medium'
                                            />
                                        </Link>
                                    )}
                                </CarouselWithAds>
                            </Section>
                        ) : null} */}
                        {/* {podcastAndReplaysData?.travel?.replays?.length ? (
                            <Section title={t('travel')} path='/podcast-replay/category/travel'>
                                <CarouselWithAds
                                    adNativeClassName='h-[220px] overflow-hidden'
                                    // @ts-ignore
                                    data={podcastAndReplaysData?.travel.replays || []}
                                >
                                    {(travel: PodcastOrReplay) => (
                                        <Link
                                            to={`/podcast-replay/type/${travel.type.toLowerCase()}/${
                                                travel.id as unknown as string
                                            }`}
                                            className='block h-full w-full'
                                        >
                                            <CardVertical
                                                thumbnailUrl={
                                                    travel?.poster ? `${travel?.poster ?? ''}` : 'cataas.com/cat'
                                                }
                                                title={travel.title}
                                                tags={[{ name: 'Travel', tag: 'Travel' }]}
                                                footerSlot={
                                                    <div className='flex justify-between items-center'>
                                                        <div>
                                                            {travel.duration
                                                                ? `${parseInt(travel.duration, 10)} ${t('sec')}`
                                                                : ''}
                                                        </div>
                                                    </div>
                                                }
                                                className='h-full'
                                                imgSize='medium'
                                            />
                                        </Link>
                                    )}
                                </CarouselWithAds>
                            </Section>
                        ) : (
                            ''
                        )} */}
                    </AdRightBlock>

                    {ads && <AdComponent ad={ads.data[4]} />}
                </IonGrid>
            ) : (
                <LoadingIndicator />
            )}
        </PageLayout>
    );
}
