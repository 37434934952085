/* eslint-disable no-bitwise */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
import { isPlatform } from '@ionic/react';
import { AdData } from 'features/advertising/generateAdPayload';

const ellipsisText = (text: string, size = 255) => {
    let newText;

    if (text?.length > size) {
        newText = text.substring(0, size);
        return `${newText}...`;
    }
    return text;
};

export default ellipsisText;

export const passwordRegex =
    // eslint-disable-next-line
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~#^()-_+=\[\]{}|;:'",.<>\/`])[A-Za-z\d@$!%*?&~#^()-_+=\[\]{}|;:'",.<>\/`]{8,}$/;

// eslint-disable-next-line
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const dateRegex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;

export const phoneRegex = /^\+[1-9]{1}[0-9]{3,14}$/;

export const mod = (n: number, m: number) => {
    return ((n % m) + m) % m;
};

export const stopAllAudio = (ignoreElement?: HTMLAudioElement) => {
    const audio = document.querySelectorAll('audio');
    const videos = document.querySelectorAll('video');
    Array.from(audio).forEach((a: HTMLAudioElement) => {
        if (a !== ignoreElement) a.pause();
    });
    Array.from(videos).forEach((v: HTMLAudioElement) => {
        if (v !== ignoreElement) v.pause();
    });
};

export function isNativeAd(data: any): data is AdData {
    return typeof data === 'object' && data !== null && (('url' in data && 'container' in data) || 'kv' in data);
}

export function getBiggestArray(args: Array<Array<unknown>>) {
    let max = -Infinity;
    // @ts-ignore
    return args.reduce((prev, curr) => {
        if (curr.length > max) {
            max = curr.length;
            return curr;
        }
        return prev;
    }, null);
}

export function transpose2dArray(a: Array<Array<unknown>>): Array<Array<unknown>> {
    return getBiggestArray(a).map(function (_, c) {
        return a.map(function (r) {
            return r[c];
        });
    });
}

export function urlObfuscateForBots(s: string) {
    const HEX = '0123456789ABCDEF';
    let encoded = '';
    for (let i = 0; i < s.length; i++) {
        // Encode 25% of characters
        if (Math.random() < 0.25) {
            const charCode = s.charCodeAt(i);
            encoded += '%';
            encoded += HEX.charAt((charCode >> 4) & 0xf);
            encoded += HEX.charAt(charCode & 0xf);
        } else {
            encoded += s.charAt(i);
        }
    } // for
    return encoded;
}
export function htmlObfuscateForBots(s: string) {
    let result = '';
    for (let j = 0; j < s.length; j++) {
        // Encode 25% of characters
        if (Math.random() < 0.25 || s.charAt(j) == ':' || s.charAt(j) == '@' || s.charAt(j) == '.') {
            const charCode = s.charCodeAt(j);
            result += '&#';
            result += charCode;
            result += ';';
        } else {
            result += s.charAt(j);
        }
    }
    return result;
}

export function obfuscateLinkForBots(plaintext: string) {
    return htmlObfuscateForBots(
        `<a href='mailto:${urlObfuscateForBots(plaintext)}>${htmlObfuscateForBots(plaintext)}</a>`,
    );
}

export function splitArrayChunks<T = unknown>(list: Array<T>, chunkSize: number) {
    if (!list.length) return [];
    const result = [];
    for (let i = 0; i < list.length; i += chunkSize) {
        const chunk = list.slice(i, i + chunkSize);
        result.push(chunk);
    }
    return result;
}

export function getQueryParams(url: string) {
    const queryStr = url.split('?')[1];
    const res: { [key: string]: string } = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const keyVal of queryStr.split('&')) {
        const [key, val] = keyVal.split('=');
        res[key] = val;
    }
    return res;
}

export function areAdjacent<T>(obj1: any, obj2: any): boolean {
    return obj2?.id === obj1?.id;
}

export function randomizeArray(arrayToRandomize: Array<any>, addedElements: any) {
    return arrayToRandomize.filter((element) => !addedElements.has(element)).sort(() => Math.random() - 0.5);
}

export function setPortraitModeOnPhone() {
    if (isPlatform('cordova')) {
        if (isPlatform('tablet')) {
            window.screen.orientation.unlock();
        } else {
            // @ts-ignore
            window.screen.orientation.lock('portrait');
        }
    }
}

let id = 0;

export function generateUniqueId(): string {
    return `${id++}`;
}

export function getPathAfterBase(url: string, base: string): string {
    try {
        const urlObj = new URL(url);
        const baseObj = new URL(base);

        if (!url.startsWith(base)) {
            throw new Error('The base URL is not a prefix of the provided URL.');
        }

        // Extract and return the pathname after the base URL

        return urlObj.pathname.substring(baseObj.pathname.length);
    } catch (error) {
        return url;
    }
}

export function isTopicRoute() {
    return window.location.pathname.startsWith('/radio/rubriken/');
}

export function convertObjectToArray(inputObject: any) {
    return Object.entries(inputObject).map(([key, value]) => {
        return { key, value };
    });
}
