import Card from 'components/Card';
import Img from 'components/Img';
import Pill from 'components/Pill';
import CardVertical from 'components/core/CardVertical';
import moment from 'moment';
import type { Event } from '../../features/event/types';

export type EventCardProps = {
    event: Event;
    onClickTag?: (c: Partial<{ category: { id: string } }>) => void;
    getTagNameFromTagGenderId: (s: string) => string | undefined;
};

export default function EventCard({ event, onClickTag, getTagNameFromTagGenderId }: EventCardProps) {
    return (
        <CardVertical
            thumbnailUrl={event.imageUrl}
            tags={event?.genre?.slice(0, 2).map((tag) => ({ name: getTagNameFromTagGenderId(tag) || '', tag }))}
            title={event.name}
            hasWavePattern={false}
            imgClassName="!h-[300px] !w-full !object-cover object-top"
            footerSlot={
                <>
                    <div>
                        {event.country} {event.city}
                    </div>
                    <div>{moment(event.startDate).format('DD.MM.YY')}</div>
                </>
            }
            imgSize="medium"
            className="h-full w-full"
            nbMaxCaractersTags={6}
            onTagClick={(tag) => onClickTag?.({ category: { id: tag.tag } })}
            linkTo={`/events/agenda/${event.id as unknown as string}`}
        />
    );
    /* return (
        <Card className='text-left h-full' hasCardHoverEffect>
            <div className='flex flex-col'>
                <div className=' -m-[10px] h-[250px]'>
                    <Img
                        src={event.imageUrl}
                        alt={event.name}
                        hasCardHoverEffect
                        className='h-[250px]'
                        imgClassName='object-cover'
                    />
                </div>
                <div className='relative pt-8'>
                    <div className='flex overflow-x-auto absolute flex -top-[6px] -ml-3'>
                        {event?.genre?.slice(0, 2).map((tag, idx) => (
                            <div
                                key={idx}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (onClickTag) {
                                        onClickTag({ category: { id: tag } });
                                    }
                                }}
                            >
                                <Pill className='truncate w-25 mx-1' key={idx}>
                                    {getTagNameFromTagGenderId(tag)}
                                </Pill>
                            </div>
                        ))}
                    </div>
                    <p className='font-semibold typo-b1 mb-4 h-[85px]'>{event.name}</p>
                    <p className='text-md typo-b2 regular'>
                        {event.country} {event.city}
                    </p>
                    <p className='text-md typo-b2 regular'>{moment(event.startDate).format('DD.MM.YY')}</p>
                </div>
            </div>
        </Card>
    ); */
}
