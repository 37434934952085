import {
    IonCol,
    IonDatetime,
    IonDatetimeButton,
    IonGrid,
    IonModal,
    IonRouterLink,
    IonRow,
    IonTitle,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Title from 'components/Title';
import MediumCard from 'components/HorizontalMediumCard';
import WavePatternContainer from 'components/WavePatternContainer';
import usePictureReports from 'features/pictureReport/hooks/usePictureReport';
import useResponsive from 'features/responsive/responsive';
import AdComponent from 'components/Ads/AdComponent';
import useAds from 'hooks/useAds';
import useEvents from 'features/event/hooks/useEvent';
import moment from 'moment';
import { GetEventsParams, getEvents } from 'lib/ApiService';
import { isNativeAd } from 'lib/utils';
import { PicturesReport } from 'types';
import LoadingIndicator from 'components/LoadingIndicator';
import PageLayout from '../components/PageLayout';
import 'swiper/css';
import EventSearchFloatingLink from '../components/EventSearch/EventSearchFloatingLink';
import { ReactComponent as FilterIcon } from '../components/Icons/filter.svg';
import Sidebar from '../components/Sidebar';
import { CloseIcon, MoonEldoIcon } from '../components/Icons';
import useEventCatgories from '../features/event/hooks/useEventCategories';
import ButtonGroup from '../components/ButtonGroup';
import Button from '../components/Button';
import Autocomplete from './Autocomplete';
import { Event } from '../features/event/types';
import EventAutocompleteOpt from '../features/event/EventAutocompleteOpt';
import { ReactComponent as NoEventsIcon } from '../components/Icons/no_events.svg';
import EventCard from '../components/EventSearch/EventCard';
import NumberBadge from '../components/core/NumberBadge';
import CardVertical from '../components/core/CardVertical';
import CarouselWithAds from '../components/Carousel/CarouselWithAds';
import CardListingChunksWithAds from '../components/CardListingChunksWithAds';
import AdRightBlock from '../components/Ads/AdRightBlock';
import PageNoData from './PageNoData';

export default function EventsPage() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const { IsDesktop, IsMobile } = useResponsive();

    const { list: pictureReports, isLoading: isLoadingPictures } = usePictureReports();
    const { eventResponse, setFilters, filters } = useEvents(
        location.state as GetEventsParams,
        undefined,
        'events-native-horizontal',
    );

    const { eventResponse: verySoonResponse, isLoading: isLoadingEvents } = useEvents(
        {
            date: new Date().toISOString(),
            endDate: moment(new Date().toISOString()).add(1, 'days').toISOString(),
        },
        'verySoonEvents',
        'events-native',
    );
    const { ads } = useAds({ page: 'event' });

    const { categories } = useEventCatgories();

    const getCategNameFromCategId = (id: string): string => {
        if (!categories?.length) return id;
        const res = categories.find((c: any) => c.id === id);
        if (!res) return id;
        return res.name;
    };

    const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);

    const [preFilters, setPreFilters] = useState<GetEventsParams>(filters);

    useEffect(() => {
        if (location.state == null) {
            setFilters({});
        } else {
            // @ts-ignore
            setFilters((f) => ({ ...f, ...location.state }));
        }
    }, [location]);

    const hasFilters = filters.date || filters.category || filters.name;

    const handleRemoveFilters = () => {
        setFilters({});
        setIsFilterSidebarOpen(false);
        setPreFilters({
            date: null,
            category: null,
        });
    };

    if (!eventResponse && !pictureReports) {
        return <PageNoData />;
    }

    if (hasFilters && eventResponse?.events.length === 0) {
        return (
            <PageLayout title={t('events')}>
                <div className="grid place-items-center">
                    <div className="text-center w-fit grid">
                        <NoEventsIcon className="min-w-[300px] w-[300px]" />
                        <div
                            className="mt-[50px] font-bold typo-h2"
                            dangerouslySetInnerHTML={{ __html: t('events_pages.no_events') }}
                        />
                        <div
                            className="mt-[30px] typo-b1 regular"
                            dangerouslySetInnerHTML={{ __html: t('events_pages.try_again') }}
                        />
                        <Button
                            onClick={handleRemoveFilters}
                            className="mt-[30px]"
                            forceHoverEffectActive
                            value={t('events_pages.back')}
                        />
                    </div>
                </div>
            </PageLayout>
        );
    }

    // "transform translate-x-0" is here to attach fixed position to this div (for the floating button)
    return (
        <PageLayout
            title={t('events')}
            hasBillboard
            cssClass="transform translate-x-0"
        >
            {!isLoadingPictures && !isLoadingEvents ? (
                <IonGrid fixed>
                    {ads && <AdComponent ad={ads.data[0]} />}
                    {IsDesktop && (
                        <>
                            {!hasFilters ? (
                                <IonRow className="mb-blockMobile md:mb-block">
                                    <Title
                                        title={t('events')}
                                        main
                                    />
                                </IonRow>
                            ) : (
                                ''
                            )}
                            <IonRow className="mb-blockMobile md:mb-block">
                                <div className="flex w-full justify-between items-center">
                                    <Autocomplete<Event>
                                        inputClassName="max-w-[700px]"
                                        className="flex-1 max-w-[700px]"
                                        optionKey="id"
                                        renderOption={(event, selected) => (
                                            <EventAutocompleteOpt
                                                event={event}
                                                selected={selected}
                                            />
                                        )}
                                        // @ts-ignore
                                        search={async (searchString: string) => {
                                            const { events } = await getEvents({ name: searchString });

                                            return events.filter((event) => !isNativeAd(event));
                                        }}
                                        onValidateSearch={(searchString: string) => {
                                            setFilters((f) => ({ ...f, name: searchString }));
                                        }}
                                    />
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsFilterSidebarOpen(true);
                                        }}
                                        className={`rounded-full ${
                                            !hasFilters ? 'bg-[var(--eldo-background-alt-color)]' : 'bg-primary'
                                        } w-[60px] h-[60px] grid place-items-center`}
                                    >
                                        <FilterIcon />
                                    </button>
                                </div>
                            </IonRow>
                            <Sidebar
                                isOpen={isFilterSidebarOpen}
                                onClose={() => {
                                    setIsFilterSidebarOpen(false);
                                    setPreFilters({
                                        date: null,
                                        category: null,
                                    });
                                }}
                            >
                                <div className="bg-[#6158DB] h-[65px] w-full px-[20px] py-[20px] flex justify-between items-center">
                                    <IonTitle className=" text-white font-bold typo-h1 leading-none">
                                        {t('your-search')}
                                    </IonTitle>
                                    <button
                                        onClick={() => {
                                            setIsFilterSidebarOpen(false);
                                            setPreFilters({
                                                date: null,
                                                category: null,
                                            });
                                        }}
                                    >
                                        <CloseIcon />
                                    </button>
                                </div>
                                <div className="p-[20px] max-h-[calc(100%-65px)] overflow-auto relative">
                                    <div className="typo-h2 font-bold mt-blockMobileInner md:mt-block-inner">
                                        {t('planning')}
                                    </div>
                                    <div className="mt-[25px]">
                                        <button
                                            className="eldo-button block cursor-pointer typo-b1 regular"
                                            onClick={() => document.getElementById('date-target')?.click()}
                                        >
                                            {preFilters.date
                                                ? moment(preFilters.date).format('DD.MM.YY')
                                                : t('pick_up_date')}
                                        </button>
                                        <IonDatetimeButton
                                            id="datetimebtn"
                                            datetime="datetime"
                                            className="hidden"
                                        >
                                            <div
                                                slot="date-target"
                                                id="date-target"
                                            />
                                        </IonDatetimeButton>

                                        <IonModal
                                            keepContentsMounted
                                            style={{ '--backdrop-opacity': 0.8, '--ion-backdrop-color': '#141414' }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <IonDatetime
                                                onIonChange={(e) =>
                                                    // @ts-ignore
                                                    setPreFilters((f) => ({ ...f, date: e.target.value }))
                                                }
                                                color="light"
                                                showDefaultButtons
                                                id="datetime"
                                                mode="md"
                                            />
                                        </IonModal>
                                    </div>

                                    {categories?.length ? (
                                        <>
                                            <div className="typo-h2 font-bold mt-block-innerMobile md:mt-block-inner">
                                                {t('type')}
                                            </div>
                                            <div className="mt-[25px] [&>*]:pl-[var(--padding-end)] -mx-[var(--padding-end)]">
                                                <ButtonGroup
                                                    className="flex-wrap"
                                                    noFirstSelect
                                                    color="tertiary"
                                                    // @ts-ignore
                                                    onChange={(opt) => setPreFilters((f) => ({ ...f, category: opt }))}
                                                    options={categories}
                                                    // @ts-ignore
                                                    value={preFilters.category}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    <div
                                        className={`w-[560px] fixed bottom-0 right-0 px-[20px] py-[20px] transition-all ${
                                            isFilterSidebarOpen
                                                ? 'opacity-100 translate-x-0'
                                                : 'select-none pointer-events-none translate-x-[100%]'
                                        }`}
                                        style={{
                                            background:
                                                'linear-gradient(180deg, rgba(27, 27, 27, 0) 0%, rgba(27, 27, 27, 0.58) 48.95%, #141414 100%)',
                                        }}
                                    >
                                        <div className="flex items-center mt-blockMobileInner md:mt-block-inner gap-[15px]">
                                            <Button
                                                value={t('reset')}
                                                onClick={() => {
                                                    setPreFilters({
                                                        date: null,
                                                        category: null,
                                                    });
                                                }}
                                            />
                                            <Button
                                                forceHoverEffectActive
                                                value={t('filter')}
                                                onClick={() => {
                                                    setFilters(() => preFilters);
                                                    setIsFilterSidebarOpen(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Sidebar>
                        </>
                    )}
                    {hasFilters && !isLoadingPictures && !isLoadingEvents ? (
                        <IonRow>
                            <IonCol size-xs={12}>
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center gap-[10px]">
                                        <Title
                                            title={t('your_results')}
                                            main
                                        />
                                        {eventResponse?.count ? <NumberBadge>{eventResponse.count}</NumberBadge> : ''}
                                    </div>
                                    <button onClick={handleRemoveFilters}>{t('remove_filters')}</button>
                                </div>

                                <CardListingChunksWithAds
                                    data={eventResponse?.events || []}
                                    className="mt-block-innerMobile md:mt-block-inner"
                                    adNativeClassName="h-[220px] overflow-hidden"
                                >
                                    {(event) => (
                                        <MediumCard
                                            key={event.id}
                                            thumbnailUrl={event.imageUrl}
                                            title={event.name}
                                            tags={event.genre.map((tag) => {
                                                return { tag, name: getCategNameFromCategId(tag) };
                                            })}
                                            onClickForPill={(tag: string) => setFilters({ category: { id: tag } })}
                                            linkTo={`/events/agenda/${event.id}`}
                                            slotEnd={`${event.country || ''} ${event.city || ''}`}
                                            date={event.startDate}
                                            truncated={2}
                                        />
                                    )}
                                </CardListingChunksWithAds>
                            </IonCol>
                        </IonRow>
                    ) : (
                        ''
                    )}
                    {!hasFilters && !isLoadingPictures ? (
                        <>
                            <IonRow>
                                <IonCol size-xs={12}>
                                    <WavePatternContainer
                                        autoMargin
                                        fixed="page"
                                        reverse
                                        position="center-right"
                                        className="md:mb-block mb-blockMobile"
                                        component="section"
                                    >
                                        <AdRightBlock ad={ads?.data?.[1]}>
                                            <Title title={t('soon_events')} />
                                            <CarouselWithAds
                                                // @ts-ignore
                                                data={verySoonResponse?.events || []}
                                                className="mt-block-innerMobile md:mt-block-inner"
                                                adNativeClassName="h-[220px] overflow-hidden"
                                            >
                                                {(recentEvent: Event) => (
                                                    <EventCard
                                                        getTagNameFromTagGenderId={getCategNameFromCategId}
                                                        event={recentEvent}
                                                        onClickTag={setFilters}
                                                    />
                                                )}
                                            </CarouselWithAds>
                                        </AdRightBlock>
                                    </WavePatternContainer>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <img
                                        src={
                                            IsDesktop
                                                ? '../assets/img/event-navigator-desktop.png'
                                                : '../assets/img/event-navigator-mobile.png'
                                        }
                                        alt="Events navigator"
                                        className={`${
                                            IsDesktop ? 'max-w-[537px]' : ''
                                        } mx-auto my-[50px] cursor-pointer transition-all duration-300 transform hover:scale-105`}
                                        onClick={() => history.push('/radio/shows/event-navigator')}
                                    />
                                </IonCol>
                            </IonRow>
                            {/* {ads && <AdComponent ad={ads.data[1]} />} */}
                            {ads && <AdComponent ad={ads.data[3]} />}
                            <IonRow>
                                <IonCol size-xs={12}>
                                    <Title
                                        title={t('pictures_report')}
                                        path="/events/fotoen"
                                    />
                                    <CarouselWithAds
                                        // @ts-ignore
                                        data={pictureReports || []}
                                        adNativeClassName="h-[220px] overflow-hidden"
                                        className="mt-block-innerMobile md:mt-block-inner"
                                    >
                                        {(report: PicturesReport) => (
                                            <Link
                                                to={`/events/fotoen/${report.id as unknown as string}`}
                                                className="block h-full w-full relative rounded"
                                            >
                                                <div className="md:duration-300 md:hover:scale-105 relative h-[470px]">
                                                    <CardVertical
                                                        thumbnailUrl={report.thumbnailUrl}
                                                        tags={report.tags}
                                                        title={report.title}
                                                        className="h-full w-full"
                                                        hasWavePattern={false}
                                                        imgClassName="!h-[300px] !w-full !object-cover object-top"
                                                    />
                                                    <div
                                                        className={`before:m-[10px] ${
                                                            IsMobile
                                                                ? 'picture-report-cut-corner-mobile'
                                                                : 'picture-report-cut-corner'
                                                        }`}
                                                    />
                                                </div>
                                            </Link>
                                        )}
                                    </CarouselWithAds>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol
                                    className="ml-auto mt-8"
                                    size-lg={4}
                                    size-xs={12}
                                >
                                    <IonRouterLink
                                        routerDirection="forward"
                                        routerLink="/radio/shows/event-navigator/archive"
                                        className="eldo-button cursor-pointer typo-b1 regular grid place-items-center"
                                    >
                                        {t('check_events_archive')}
                                    </IonRouterLink>
                                </IonCol>
                            </IonRow>
                        </>
                    ) : (
                        ''
                    )}
                    {ads && !isLoadingEvents && !isLoadingPictures && (
                        <AdComponent
                            ad={ads.data[4]}
                            className="mt-[50px]"
                        />
                    )}
                    {/* Suggest an event needs to be activated on V2 */}
                    {/* <IonRow>
                    <IonCol className="ml-auto mt-8" size-xs={12}>
                        <Card className='md:px-40 p-6 flex-col cut-off-corner'>
                            <OrnamentIcon className='absolute top-0 right-0 w-24'/>
                            <CalendarIcon className='mb-4 z-10'/>
                            <p className='z-10 ml-2 text-lg'>{t('suggest_event_text')}</p>
                            <div className='grid md:grid-cols-3 mt-4'>
                                <IonRouterLink
                                    routerDirection='forward'
                                    routerLink='/radio/shows/event-navigator/archive'
                                    className='eldo-button block cursor-pointer'
                                >
                                    {t('suggest_event_button')}
                                </IonRouterLink>
                            </div>
                        </Card>
                    </IonCol>
                </IonRow> */}
                </IonGrid>
            ) : (
                <LoadingIndicator />
            )}
            <EventSearchFloatingLink />
        </PageLayout>
    );
}
