import { generateId } from '@ionic/react';
import { AdData } from 'features/advertising/generateAdPayload';
import { isNativeAd } from 'lib/utils';
import { useState, useMemo, MouseEventHandler } from 'react';
import AdComponent from 'components/Ads/AdComponent';
import Carousel, { CarouselProps } from '.';

export type CarouselWithAdsProps<T> = Omit<CarouselProps<T>, 'itemKey'> & {
    children: (item: T) => React.ReactNode;
    className?: any;
    data: Array<T | AdData>;
    getKey?: (item: T) => string | number;
    adNativeClassName?: string;
    slidesPerView?: number;
    isGallery?: boolean;
};

export default function CarouselWithAds<T = unknown>({
    children,
    className,
    data,
    getKey,
    adNativeClassName,
    slidesPerView,
    isGallery = false,
    ...props
}: CarouselWithAdsProps<T>) {
    const [adHiddens, setAdHiddens] = useState<string[]>([]);
    const hideAd = (id: string) => {
        setAdHiddens((prev) => [...(prev || []), id]);
    };

    const getComputedKey =
        // @ts-ignore
        getKey || ((item: T | AdData) => item?.id || item?.containerId || generateId('ads-carousel'));

    const computedData = useMemo<(T | AdData)[]>(() => {
        if (!data?.length) return [];
        if (!adHiddens?.length) return data;

        const isAdUrlHidden = (id: string) => Boolean(adHiddens.find((k) => k === id));

        return data.filter((d) => !isNativeAd(d) || !isAdUrlHidden(d?.containerId));
    }, [data, adHiddens]);

    const handleClickOnIframeOverlay: MouseEventHandler = (event) => {
        // @ts-ignore
        const adElement = event.target?.previousElementSibling;
        const iframeAdElement: HTMLIFrameElement = adElement?.children?.[0];
        if (adElement == null || iframeAdElement == null) return;
        if (
            // @ts-ignore
            iframeAdElement.contentDocument?.body.firstElementChild.firstElementChild.children[0]
        ) {
            // @ts-ignore
            iframeAdElement.contentDocument?.body.firstElementChild.firstElementChild.children[0].click();
        }
        if (
            // @ts-ignore
            iframeAdElement.contentDocument?.body.firstElementChild.firstElementChild.children[1]
        ) {
            // @ts-ignore
            iframeAdElement.contentDocument?.body.firstElementChild.firstElementChild.children[1].click();
        }
    };

    return (
        <Carousel
            className={className}
            data={computedData}
            // @ts-ignore
            itemKey={getComputedKey}
            {...props}
            slidesPerView={slidesPerView}
            isGallery={isGallery}
        >
            {(item) => {
                return !isNativeAd(item) ? (
                    children(item)
                ) : (
                    <div className="relative w-full h-full">
                        <AdComponent
                            // @ts-ignore
                            key={getComputedKey(item)}
                            onNoContent={() => hideAd(item.containerId)}
                            ad={item}
                            hasSpacings={false}
                            className={`${adNativeClassName || ''} h-full w-full`}
                        />
                        <div
                            className="absolute top-0 left-0 z-10 w-full h-full cursor-pointer"
                            onClick={handleClickOnIframeOverlay}
                        />
                    </div>
                );
            }}
        </Carousel>
    );
}
