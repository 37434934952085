import { generateId } from '@ionic/react';
import CarouselWithAds from 'components/Carousel/CarouselWithAds';
import { getThumbnail } from 'components/core/ArticlePageComponent';
import CardVertical from 'components/core/CardVertical';
import useResponsive from 'features/responsive/responsive';
import { areAdjacent, getPathAfterBase, isNativeAd, randomizeArray } from 'lib/utils';
import moment from 'moment';
import { getThumbnailForActions } from 'pages/ActionsPage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { Swiper as SwiperClass } from 'swiper/types';
import { ActionList, Article, Focus, PicturesReport, PodcastOrReplay } from 'types';

type FocusSwiperProps = {
    focuses: Focus[];
};

type FocusWithKey = Focus & { key: string };

export function isArticle(resource: Article | PicturesReport | ActionList | PodcastOrReplay): resource is Article {
    return (resource as Article)?.header !== undefined;
}

export function isAction(resource: Article | PicturesReport | ActionList | PodcastOrReplay): resource is ActionList {
    return (resource as ActionList)?.image !== undefined;
}

export function isReplay(resource: Article | PicturesReport | ActionList | PodcastOrReplay): resource is ActionList {
    return (resource as PodcastOrReplay)?.poster !== undefined;
}

function FocusSwiper({ focuses }: FocusSwiperProps) {
    const { IsMobile } = useResponsive();
    const isTablet1 = useMediaQuery({ minWidth: 768, maxWidth: 992 });
    const isTablet2 = useMediaQuery({ minWidth: 992, maxWidth: 1200 });
    const [displayedFocuses, setDisplayedFocuses] = useState<FocusWithKey[]>([]);
    const [nextLoopList, setNextLoopList] = useState<Focus[]>([]);

    useEffect(() => {
        const data = focuses.filter((f) => {
            const isDateAfterFrom = Date.parse(f.from) - Date.now() < 0;
            const isDateBeforeTo = Date.now() - Date.parse(f.to) < 0;
            return (isDateAfterFrom && isDateBeforeTo) || isNativeAd(f);
        });
        setDisplayedFocuses(() => data.map((f) => ({ ...f, key: generateId() })));
        setNextLoopList(() => data);
    }, [focuses]);

    const { t } = useTranslation();
    const history = useHistory();

    function getLink(f: Focus): string {
        if (f.type === 'aktioun') {
            return `/aktiounen/${f.resourceIdentifier as unknown as string}`;
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const resource = f.resource as Article | PicturesReport;
        if (f.type === 'article') {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return `${f.resource.category_path || '/aktuell/news'}/a/${f.resource?.id as unknown as string}`;
        }
        if (f.type === 'externalLink') {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return f.externalUrl ?? '';
        }
        if (f.type === 'replay') {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return `/podcast-replay/type/replay/${resource?.id as unknown as string}`;
        }
        return `/events/fotoen/${resource?.id as unknown as string}`;
    }

    function mergeAndRandomizeArrays<T>(array1: Array<any>, array2: Array<any>): Array<T> {
        if (array2.length <= 2) {
            return [...array1, ...array2];
        }
        const mergedArray: Array<any> = [...array1, ...array2];
        const chunkSize = array1.length;
        const addedElements = new Set<T>(array1);

        // lets keep this logic which shuffles things after the first list
        // if the cliend wants this back we shall reuse it
        // // Shuffle the array2 before chunking
        // const shuffledArray2 = array2.sort(() => Math.random() - 0.5);

        // for (let i = 0; i < shuffledArray2.length; i += chunkSize) {
        //     let chunk = shuffledArray2.slice(i, i + chunkSize);
        //     let randomizedChunk = randomizeArray(chunk, addedElements);

        //     // Continuously rerandomize the chunk until adjacent elements are not next to each other
        //     const indexForFirstArray = mergedArray[mergedArray.length - 1]?.id
        //         ? mergedArray.length - 1
        //         : mergedArray.length - 2;
        //     const indexForSecondArray = randomizedChunk[0]?.id ? 0 : 1;
        //     while (
        //         mergedArray.length > 0 &&
        //         areAdjacent(mergedArray[indexForFirstArray], randomizedChunk[indexForSecondArray])
        //     ) {
        //         chunk = shuffledArray2.slice(i, i + chunkSize);
        //         randomizedChunk = randomizeArray(chunk, addedElements);
        //     }

        //     mergedArray.push(...randomizedChunk);
        //     randomizedChunk.forEach((element) => addedElements.add(element));
        // }

        return mergedArray;
    }

    function addNextSlides() {
        const newData: Focus[] = mergeAndRandomizeArrays(displayedFocuses, nextLoopList);
        setDisplayedFocuses(newData.map((d) => ({ ...d, key: generateId() })));
    }

    const onActiveIndexChange = (swiper: SwiperClass) => {
        if (nextLoopList?.length <= 1) return;
        if (swiper.realIndex === displayedFocuses.length - 2) {
            addNextSlides();
        }
    };

    const onReachEnd = (swiper: SwiperClass) => {
        addNextSlides();
    };

    // This should be having 3 different types to load the images
    function getImageForCard(f: Focus) {
        const types = ['focus', 'articleANDListing', 'articleONLY'];
        if (f.type === 'externalLink') {
            return f.externalPicture;
        }
        if (f.type === 'replay') {
            // @ts-ignore
            return (f.resource as PodcastOrReplay).poster;
        }
        if (isAction(f.resource)) {
            return getThumbnailForActions(f.resource, types);
        }
        if (isArticle(f.resource)) {
            return getThumbnail(f.resource, types);
        }
        // @ts-ignore
        return f.resource?.thumbnailUrl;
    }

    function getSliderPerViewForFocus() {
        if (IsMobile) return 1.45;
        if (isTablet1) return 2;
        return isTablet2 ? 3 : 4;
    }

    return (
        <CarouselWithAds
            data={displayedFocuses}
            getKey={(item) => item.key}
            className="mt-block-innerMobile md:mt-block-inner"
            freeMode
            onActiveIndexChange={onActiveIndexChange}
            onReachEnd={onReachEnd}
            onReachBeginning={onActiveIndexChange}
            height={0}
            forceNavEndArrowsVisible
            slidesPerView={getSliderPerViewForFocus()}
        >
            {(f) => (
                <CardVertical
                    // @ts-ignore
                    thumbnailUrl={getImageForCard(f)}
                    title={f?.title}
                    subtitle=""
                    className="h-full w-full"
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                    tags={(f.resource as Article | PicturesReport)?.tags ?? []}
                    hasWavePattern={false}
                    nbMaxCaractersTags={8}
                    onTagClick={(tag) => {
                        const tagClicked = tag?.publicTag || '';
                        // @ts-ignore
                        if (tagClicked === f.resource?.tags?.[0]?.publicTag && f.resource?.category_path?.length) {
                            // @ts-ignore
                            history.push(f.resource?.category_path);
                        } else {
                            history.push(`/aktuell/news/${tagClicked || ''}`);
                        }
                    }}
                    linkTo={getPathAfterBase(getLink(f), 'https://www.eldo.lu/')}
                    externalLink={f.externalUrl?.includes('eldo.lu/') ? undefined : f.externalUrl}
                />
            )}
        </CarouselWithAds>
    );
}

export default FocusSwiper;
