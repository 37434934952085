import { IonGrid, IonRow } from '@ionic/react';
import { useState } from 'react';
import moment from 'moment';
import useAds from 'hooks/useAds';
import AdComponent from 'components/Ads/AdComponent';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CardVertical from 'components/core/CardVertical';
import CardListingChunksWithAds from 'components/CardListingChunksWithAds';
import PageLayout from 'components/PageLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import Title from 'components/Title';
import useResponsive from 'features/responsive/responsive';
import ButtonGroup from 'components/ButtonGroup';
import { ActionList, Article } from 'types';
import useActionList from 'features/actions/hooks/useActionsList';
import { getActionArticle } from 'lib/ApiService';
import { getThumbnailForArticle } from 'components/core/ArticlePageComponent';

export function getThumbnailForActions(article: ActionList | undefined, types: string[]) {
    if (article) {
        return (
            types
                // @ts-ignore
                .map((type) => article.image?.[type]?.thumbnailUrl)
                .find((thumbnailUrl) => thumbnailUrl && thumbnailUrl !== '') || ''
        );
    }
    return '';
}

export function getCopyrightForActions(article: ActionList | undefined, types: string[]) {
    if (article) {
        return (
            // @ts-ignore
            types.map((type) => article.image?.[type]?.copyright).find((copyright) => copyright && copyright !== '') ||
            ''
        );
    }
    return '';
}

export function getCopyrightForAction(article: ActionList | undefined) {
    const types = ['articleONLY', 'focus', 'listing_aktiounen', 'articleANDListing'];
    return getCopyrightForActions(article, types);
}

export function isActionList(resource: Article | ActionList): resource is ActionList {
    return 'slug' in resource;
}

function ActionsPage({ isCompactView }: ActionsPageProps) {
    const { ads } = useAds({ page: 'actions' });
    const { t } = useTranslation();
    const { IsDesktop } = useResponsive();
    const buttonOptions = [
        {
            id: 0,
            name: 'Current',
        },
        //
        // {
        //     id: 1,
        //     name: 'Archived'
        // }
    ];
    const [currentCategory, setCurrentCategory] = useState<number>(0);
    const actions = useActionList(buttonOptions[currentCategory].name === 'Current');
    const title = t('actions.title');

    return (
        <PageLayout
            title={title}
            cssClass="news-page"
        >
            <IonGrid fixed>
                {IsDesktop && title && (
                    <IonRow className="mb-blockMobile md:mb-block">
                        <Title
                            title={title}
                            main
                        />
                    </IonRow>
                )}
                {ads && <AdComponent ad={ads.data[0]} />}
                <ButtonGroup
                    onChange={(opt) => {
                        // @ts-ignore
                        setCurrentCategory(opt.id);
                    }}
                    options={buttonOptions || []}
                />
                {!actions.isLoading ? (
                    <CardListingChunksWithAds
                        className="mb-blockMobile md:mb-block mt-blockMobile md:mt-block"
                        adsBetween={ads ? [ads.data[1]] : []}
                        data={actions.data?.filter((action) => isActionList(action)) || []}
                        adNativeClassName="!h-[325px] overflow-hidden"
                    >
                        {(m: ActionList | Article) =>
                            isActionList(m) ? (
                                <Link
                                    to={`/aktiounen/${m.slug as unknown as string}`}
                                    key={m.slug}
                                    className="!h-[340px]"
                                >
                                    <CardVertical
                                        className="w-full !h-[340px] pb-4"
                                        title={m.title}
                                        thumbnailUrl={m?.image?.[0] || '/assets/img/placeholderimg2.svg'}
                                        imgSize="small"
                                        hasWavePattern={false}
                                    />
                                </Link>
                            ) : (
                                <Link
                                    to={`/aktuell/news/a/${m.id as unknown as string}`}
                                    key={m.id}
                                    className="!h-[340px]"
                                >
                                    <CardVertical
                                        className="w-full !h-[340px] pb-4"
                                        title={m.title}
                                        thumbnailUrl={getThumbnailForArticle(m)}
                                        imgSize="small"
                                        hasWavePattern={false}
                                    />
                                </Link>
                            )
                        }
                    </CardListingChunksWithAds>
                ) : (
                    <LoadingIndicator />
                )}
                {/* {actions.canLoadMore ? (
                    <IonRow className='mb-blockMobile md:mb-block'>
                        <div className='m-auto h-4' ref={observerTarget} />
                    </IonRow>
                ) : (
                    ''
                )} */}
                {ads && <AdComponent ad={ads.data[3]} />}
            </IonGrid>
        </PageLayout>
    );
}

type ActionsPageProps = {
    isCompactView?: boolean;
};

ActionsPage.defaultProps = {
    isCompactView: false,
};

export default ActionsPage;
