import { IonRow, RefresherEventDetail } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import useNews from 'features/news/hooks/useNews';
import { useEffect, useRef } from 'react';
import { AdData, AdResponse } from 'features/advertising/generateAdPayload';
import { useHistory } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import CardListingChunksWithAds from './CardListingChunksWithAds';
import HorizontalMediumCard from './HorizontalMediumCard';
import { getThumbnailForHorizontalArticleList } from './core/ArticlePageComponent';
import LoadingIndicator from './LoadingIndicator';

type LoadMoreProps = {
    tag?: string;
    categoryId?: number;
    ads?:
        | AdResponse
        | {
              data: [];
          }
        | undefined;
    trigger?: boolean;
    setTrigger?: React.Dispatch<React.SetStateAction<boolean>>;
    adPosition?: number;
    className?: string;
};

export default function NewsInfiniteScroll({
    tag,
    categoryId,
    ads,
    trigger,
    setTrigger,
    adPosition,
    className,
}: LoadMoreProps) {
    const { t } = useTranslation();
    const observerTarget = useRef(null);
    const news = useNews({ tag, categoryId });
    const history = useHistory();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (trigger && setTrigger) {
            queryClient.clear();
            news.refetch();
            setTrigger(false);
        }
    }, [trigger]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    news.loadMore();
                }
            },
            { threshold: 1 },
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget, news]);

    return (
        <IonRow className={clsx('mb-blockMobile md:mb-block', className)}>
            <IonRow className="w-full">
                {!news.isLoading ? (
                    <CardListingChunksWithAds
                        adsBetween={ads ? [ads.data[adPosition ?? 1]] : []}
                        data={news?.list}
                        adNativeClassName="!h-[200px] overflow-hidden"
                        className={clsx(
                            'mb-blockMobile md:mb-block',
                            !categoryId && !tag && 'mt-blockMobile md:mt-block',
                        )}
                    >
                        {(item) => (
                            <HorizontalMediumCard
                                title={item.title}
                                date={item.date}
                                tags={item.tags ?? []}
                                thumbnailUrl={getThumbnailForHorizontalArticleList(item)}
                                truncated={3}
                                linkForPill="/aktuell/news/"
                                imageCover={item.header?.listing_news ? 'object-fit' : 'object-cover'}
                                linkTo={`${item.category_path !== '' ? item.category_path ?? '' : '/aktuell/news'}/a/${
                                    item.id as unknown as string
                                }`}
                                className="max-h-[175px]"
                                onClickForPill={(tagClicked) => {
                                    if (tagClicked === item?.tags?.[0]?.publicTag && item.category_path?.length) {
                                        history.push(item.category_path);
                                    } else {
                                        history.push(`/aktuell/news/${tagClicked}`);
                                    }
                                }}
                            />
                        )}
                    </CardListingChunksWithAds>
                ) : (
                    <LoadingIndicator />
                )}
                {news.canLoadMore ? (
                    <div
                        className="m-auto h-4"
                        ref={observerTarget}
                    />
                ) : null}
            </IonRow>
        </IonRow>
    );
}
